<template>
  <div class="login-container">
    <span class="logo-yitu-cen">Powered by</span>
    <img src="../../assets/login-logo-yitu.png" class="logo-yitu" />
    <div class="des" ref="des">
      <svg-icon icon-class="kouhao1" style="width:100%;height:auto" />
    </div>
    <div class="login-form-layout">
      <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" auto-complete="on"
        label-position="left">
        <div v-if="isCode" class="title-container">
          <img src="../../assets/edyeesp.jpg" class="login_logo" />
          <h1 class="title">Log In</h1>

        </div>
        <div v-if="isCode" class="landing-content">
          <el-form-item prop="userName" style="margin: 30px auto">
            <span class="svg-container" style="font-size: 24px">
              <svg-icon icon-class="user" />
            </span>
            <el-input v-model="loginForm.userName" :placeholder="$t('login.login.usernameOrEmail')" name="userName"
              type="text" auto-complete="on" />
          </el-form-item>

          <el-form-item prop="userPwd" style="margin: 30px auto">
            <span class="svg-container">
              <svg-icon icon-class="password" />
            </span>
            <el-input v-model="loginForm.userPwd" :type="passwordType" :placeholder="$t('accounts.common.password')"
              name="userPwd" auto-complete="on" @keyup.enter.native="handleLogin" />
            <span class="show-pwd" @click="showPwd">
              <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" />
            </span>
          </el-form-item>
          <!-- 原登录次数错误三次才需要输入验证码逻辑 （加上没起作用并且导致第一次不能输入会报错所以先去掉校验）v-if="loginForm.err_size >= 3" -->
          <el-form-item prop="verCode">
            <span class="svg-container">
              <span class="el-icon-picture" />
            </span>
            <el-input name="verCode" type="text" @keyup.enter.native="handleLogin" v-model="loginForm.verCode"
              autocomplete="on" :placeholder="$t('login.login.enterCaptcha')" />
            <img :src="imgUrl" @click="getCodeFn" class="vcode" />
          </el-form-item>
          <el-button :loading="loading" type="primary" style="width: 320px" @click.native.prevent="handleLogin">{{
            $t('login.login.loginButton') }}</el-button>
          <div class="privacy">
            <template>
              <el-checkbox class="checkbox-login" v-model="loginChecked"></el-checkbox>
            </template>
            <div class="privacy-a">{{ $t('login.login.readAndAgree') }}<a @click="services">{{
              $t('login.login.termsOfService')
                }}</a>,
              <a @click="userPri">{{ $t('login.login.privacyPolicy') }}</a>
            </div>
          </div>
          <div class="login-footer">
            <div class="locale-switcher">
              <LocaleSwitcher style="border: 1px solid #cfcedb" />
            </div>
            <div class="forget-password" @click="goForget">{{ $t('login.login.forgotPassword') }}</div>

          </div>

        </div>
      </el-form>
    </div>
    <div style="
        display: flex;
        justify-content: space-around;
        color: #ffffff;
        font-size: 14px;
        width: 100%;
        bottom: 20px;
        position: fixed;
      ">
      <!-- <p>{{ this.$t('login.businessContact') }} 丨 {{ this.$t('login.Technical Support') }}：info@e-dye.com
      </p> -->
      <!-- © 2022 亿涂(苏州)互联网科技有限公司 版权所有 | -->
      <!-- <a href="http://beian.miit.gov.cn/">苏ICP备2021008618号</a> -->
      <div class="customerseryice">Customer Service: info@e-dye.com</div>
    </div>
    <update-password v-if="updatePasswordVisible" ref="updatePassword"></update-password>
  </div>
</template>
<script>
import { cryptoAes } from '@/utils/index'
import { sendCode, getCodeApi, firstLoginCheckerLoginApi } from '@/api/login'
import { Checkbox, Message } from 'element-ui'
import Cookies from 'js-cookie'
import resize from '@/utils/resize'
import LocaleSwitcher from '@/components/LocaleSwitcher'
import UpdatePassword from './components/updatepassword'
export default {
  name: 'login',
  mixins: [resize],
  data() {
    const validatePassword = (rule, value, callback) => {
      if (value.length < 4) {
        callback(new Error(this.$t('login.login.enterValidPassword')))
      } else {
        callback()
      }
    }
    return {
      updatePasswordVisible: false,
      loginChecked: true,
      loginForm: {
        userName: '',
        userPwd: '',
        phone: '',
        phone_: '',
        verKey: '',
        verCode: '',
        err_size: '0'
      },
      isCode: true,
      tips: '获取验证码',
      disabled: false,
      loginRules: {
        userName: [
          { required: true, trigger: 'blur', message: this.$t('login.login.enterValidUsernameOrEmail') }
        ],
        code: [{ required: true, trigger: 'blur', message: this.$t('login.login.validateCaptcha') }],
        userPwd: [
          { required: true, trigger: 'blur', validator: validatePassword }
        ],
        verCode: [{ required: true, trigger: 'blur', message: this.$t('login.login.enterCaptcha') }]
      },
      passwordType: 'password',
      loading: false,
      showDialog: false,
      imgUrl: ''
    }
  },
  created() {
    this.loginForm.err_size = Cookies.get('ERR_SIZE')
    this.getCodeFn()
  },
  components: {
    LocaleSwitcher,
    UpdatePassword
  },
  methods: {
    cacheLocale(locale) {
      Cookies.set('locale', locale)
      location.reload()
    },
    getCodeFn() {
      getCodeApi()
        .then((data) => {
          this.codeError = false
          this.imgUrl = data.codeUrl
          this.loginForm.verKey = data.key
        })
        .catch(() => {
          this.codeError = true
        })
    },
    services() {
      // this.$router.push('/serviceAgreement')
      const routeUrl = this.$router.resolve('/serviceAgreement').href;
      window.open(routeUrl, '_blank');
    },
    userPri() {
      // this.$router.push('/personalPrivacy')
      const routeUrl = this.$router.resolve('/personalPrivacy').href;
      window.open(routeUrl, '_blank');

    },
    // 跳转页面
    goForget() {
      this.$router.push('/forget')
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = 'text'
      } else {
        this.passwordType = 'password'
      }
    },
    getCode() {
      if (!this.loginForm.phone) {
        Message.error(this.$t('tips.verification.phoneNumber'))
        return
      }
      if (!/^1[3456789]\d{9}$/.test(this.loginForm.phone)) {
        Message.error(this.$t('tips.phone.failed'))
        return
      }
      this.validateBtn()
      var params = {
        mobile: this.loginForm.phone,
        userName: this.loginForm.userName
      }
      sendCode(params).then(({ data }) => {
        Message.success('验证码已发送！')
      })
    },
    validateBtn() {
      //倒计时
      let time = 120
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer)
          this.disabled = false
          this.tips = '获取验证码'
        } else {
          this.tips = time + '秒后重试'
          this.disabled = true
          time--
        }
      }, 1000)
    },

    CheckLoginApi() {
      firstLoginCheckerLoginApi(this.loginForm)
        .then((res) => {
          console.log(res)

        })
    },

    handleLogin() {
      if (this.isCode) {
        this.loginForm.code = ''
      } else {
        this.loginForm.verCode = ''
      }
      if (!this.loginChecked) {
        this.$message({
          message: this.$t('login.login.readAndConfirmedPrivacyUseTerms'),
          type: 'warning'
        })
        return;
      }
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true
          const params = {
            userName: this.loginForm.userName,
            userPwd: cryptoAes(this.loginForm.userPwd),
            verKey: this.loginForm.verKey,
            verCode: this.loginForm.verCode
          }
          this.$store
            .dispatch('Login', params)
            .then((res) => {
              Cookies.set('ERR_SIZE', '0')
              setTimeout(() => { this.$router.push({ path: '/' }) }
                , 500)

              if (Cookies.getJSON('devicedata')) {
                Cookies.remove('devicedata')
              }
              if (res.dateExpire) {
                this.$message({
                  message: '您的服务有效期已不足90天',
                  type: 'warning',
                  showClose: true,
                  duration: 0
                })
              }
              this.loading = false
            })
            .catch((err) => {
              this.loginForm.err_size = 3
              Cookies.set('ERR_SIZE', '3')
              this.loading = false
              this.getCodeFn()
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })


    }
  }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
$bg: #283443;
$light_gray: #eee;
$cursor: #fff;

.login-footer {
  justify-content: space-between;
  width: 320px;
  margin-top: 20px;
}

.locale-switcher {
  margin-right: 10px;
  float: left;
}

.forget-password {
  width: 97px;
  height: 20px;
  margin-top: 7px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 20px;
  color: #000000;
  opacity: 1;
  cursor: pointer;
  margin-right: 9px;
  float: right;
  text-align: right;
}

.customerseryice {
  margin-left: 970px;
}

/* reset element-ui css */
.login-container {
  background: rgba(13, 78, 230, 0.06) url('../../assets/e.dye-login-backg.jpg') center center no-repeat;

  .logo {

    width: 470px;
    height: auto;
    position: fixed;
    top: -2%;
    left: -0.1%;
  }

  .logo-yitu {
    width: 130px;
    height: auto;
    position: fixed;
    top: 88%;
    left: 90%;
    opacity: 0.4;
  }

  .logo-yitu-cen {
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    width: 200px;
    height: auto;
    position: fixed;
    top: 88.5%;
    left: 93%;
  }

  .privacy {
    display: flex;
    align-items: center;
    font-size: 11px;
    padding-top: 10px;
  }

  a,
  a:focus,
  a:hover {
    cursor: pointer;
    color: #706ca5;
    text-decoration: none;
  }

  .des {
    position: fixed;
    top: 50%;
    margin-top: -140px;
    // margin-top: -139.5px;
    left: 10%;
    max-width: 731px;
  }

  .login-form-layout {
    position: absolute;
    top: 50%;
    left: 58%;
    margin-top: -270px;
    background: #fefdfd;
    box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.18);
    opacity: 1;
    border-radius: 10px;
    margin-left: 135px;
    margin-top: -186px;

    .forget {
      width: 56px;
      height: 20px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 20px;
      color: #000000;
      opacity: 1;
      cursor: pointer;
      margin-right: 10px;
      float: right;
      margin-top: 10px;
    }
  }

  .landing-content {
    width: 320px;
    height: 249px;
    margin: 0 auto;
  }

  .landing-content ::v-deep {
    .el-input__inner {
      border: 0;
    }
  }

  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 0px;
      height: 47px;
      caret-color: $cursor;

      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px $bg inset !important;
        -webkit-text-fill-color: $cursor !important;
        background-color: transparent !important;
      }
    }
  }

  .el-form-item {
    width: 320px;
    border-bottom: 1px solid #706ca5;
  }

  .el-button--primary {
    width: 320px;
    height: 50px;
    background: #42d6d7;
    opacity: 1;
    border-radius: 5px;
    margin-top: 8px;
    border-color: white;
  }

  .el-input:-webkit-autofill:focus {
    // -webkit-box-shadow: none!important;
    // -webkit-text-fill-color: white;
  }
}

.login-container .el-input input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px #f7f7f7 inset !important;
  -webkit-text-fill-color: #000 !important;
}
</style>

<style rel="stylesheet/scss" lang="scss" scoped>
// $bg:#2d3a4b;
$bg: transparent;
$dark_gray: #2e414e;
$light_gray: #eee;

.login-container {
  min-height: 100%;
  width: 100%;
  overflow: hidden;
  background-size: cover;

  .vcode {
    position: absolute;
    width: 100px;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
  }

  .login-form {
    width: 420px;
    //height: 430px;
    max-width: 100%;
    overflow: hidden;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 30px 0;
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 1px;
    color: $dark_gray;
    vertical-align: middle;
    width: 40px;
    display: inline-block;
    font-size: 21px;
  }

  .title-container {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;

    //padding: 50px 0 30px 0;
    .title {
      width: 260px;
      height: 28px;
      font-size: 30px;
      font-family: PingFang SC;
      font-weight: bold;
      line-height: 4px;
      color: #000000;
      opacity: 1;
      margin: -10px 130px;
      text-align: center;
    }

    .login_logo {
      // height: 152px;
      width: 202px;
      margin-left: 25px;
      margin-bottom: -52px;
      margin-top: -48px;
    }

    .title_ {
      height: 28px;
      line-height: 28px;
      color: #666;
      opacity: 1;
      margin: 10px;
      padding-left: 40px;
    }

    .line {
      width: 80px;
      height: 3px;
      background: #42d6d7;
      opacity: 1;
      margin: 0 auto;
    }

    .set-language {
      color: #fff;
      position: absolute;
      top: 3px;
      font-size: 18px;
      right: 0px;
      cursor: pointer;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }

  .code {
    // width: 220px;
    margin-bottom: 10px;
    // margin:30px 0 !important;
    position: relative;
  }

  .send-code {
    width: 170px;
    text-align: right;
    position: absolute;
    top: 0px;
    right: 10px;
  }

  .code_ {
    border: 1px solid #595757;
    background-color: #ffffff;
    color: #595757;
    font-size: 12px;
  }
}

@media screen and (min-width: 1024px) {
  .des {
    width: 450px;
  }
}

/*>=1024的设备屏幕*/

@media screen and (min-width: 1100px) {
  .des {
    width: 450px;
  }
}

/*>=1100的设备屏幕*/
@media (min-width: 1280px) {
  .des {
    width: 500px;
  }
}

/*>=1280的设备屏幕*/

@media screen and (min-width: 1366px) {
  .des {
    width: 531px;
  }
}

/*>=1366的设备屏幕*/

@media screen and (min-width: 1440px) {
  .des {
    width: 631px;
  }
}

/*>=1440的设备屏幕*/

@media screen and (min-width: 1680px) {
  .des {
    width: 731px;
  }
}

/*>=1680的设备屏幕*/
@media screen and (min-width: 1920px) {
  .des {
    width: 731px;
  }
}

/*>=1920的设备屏幕*/
img {
  image-rendering: -moz-crisp-edges;
  /* Firefox */
  image-rendering: -o-crisp-edges;
  /* Opera */
  image-rendering: -webkit-optimize-contrast;
  /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  /* IE (non-standard property) */
}

.checkbox-login {
  margin-right: 0;
  padding-right: 9px;
  margin-bottom: 3px;
}
</style>
