<template>
  <div class="syMain">
    <!-- <img src="@/assets/syBanner.png" alt="" /> -->
    <div class="elDelogLogin">
      <el-dialog :title="$t('accounts.common.changePassword.title')" :close-on-click-modal="false" :visible.sync="visible
        " width="700px" :close-on-press-escape="false" :show-close="false">
        <div>
          <el-steps :active="active" align-center>
            <el-step :title="$t('login.setPassword.firstTimeLogin')" />
            <el-step :title="$t('login.setPassword.updateDefaultPassword')" />
            <el-step :title="$t('login.setPassword.complete')" />
          </el-steps>
          <div v-if="active == 1" class="topTip">
            {{ $t('login.setPassword.pleaseSetPassword') }}
          </div>
          <div v-if="active != 3" style="width: 60%; margin: 0 auto;text-align:center">
            <el-form ref="dataForm" :model="dataForm" status-icon :rules="dataRule">
              <el-form-item v-show="active == 1" :label="$t('accounts.common.username')">
                <el-input v-model="dataForm.userName" :disabled="true" size="medium" />
              </el-form-item>
              <template v-if="active == 2">
                <el-form-item :label="$t('accounts.common.changePassword.currentPassword')" prop="userPwd"
                  class="password-item">
                  <el-input type="password" v-model="dataForm.userPwd" autocomplete="off" :show-password="true">
                  </el-input>
                </el-form-item>

                <el-form-item :label="$t('login.resetPassword.newPassword')" prop="newPassword" class="password-item">
                  <el-input type="password" v-model="dataForm.newPassword" autocomplete="off"
                    :show-password="true"></el-input>
                </el-form-item>
                <el-form-item :label="$t('accounts.common.confirmPassword')" prop="comfirmPassword"
                  class="password-item">
                  <el-input type="password" v-model="dataForm.comfirmPassword" :show-password="true"
                    autocomplete="off"></el-input>
                </el-form-item>
              </template>
            </el-form>
            <div v-if="active != 1" slot="footer" class="dialog-footer">
              <el-button @click="resetForm('form')">{{ $t('common.buttons.back') }}</el-button>
              <el-button type="primary" @click="dataFormSubmit()">{{ $t('common.buttons.next') }}</el-button>
            </div>
            <div v-else slot="footer" class="dialog-footer">
              <el-button type="primary" style="width:75%" @click="nextTip">{{ $t('common.buttons.next') }}</el-button>
            </div>
          </div>
          <div v-if="active === 3" class="ImgTip">
            <div style="margin:20px 0">
              <img v-if="isSuccess === true" src="../../../assets/img/updatePwd.png" alt="">
              <!-- <img v-else src="@/images/filer.png"> -->
            </div>
            <p v-if="isSuccess === true">{{ $t('login.setPassword.setSuccessful') }}</p>
            <p v-else>{{ $t('login.setPassword.connectionErrorTryAgain') }}</p>
            <el-button v-if="isSuccess === true" type="primary" @click="logBackIn()">{{
              $t('login.setPassword.loginAgain')
            }}</el-button>
            <el-button v-else type="primary" @click="again">{{ $t('login.setPassword.resetPassword') }}</el-button>
          </div>
          <div v-if="active == 2" class="tip">
            <h4>{{ $t('login.setPassword.ensureSecurityPasswordRequirementsTitle') }}</h4>
            <p>{{ $t('login.setPassword.ensureSecurityPasswordRequirements1') }}</p>
            <p>{{ $t('login.setPassword.ensureSecurityPasswordRequirements2') }}</p>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { updateInitializePwdApi } from "@/api/modules/user";
import { cryptoAes, decrypt } from "@/utils/index";
import {
  getUserInfoLocation,
  setUserInfoLocation,
  removeUserInfoLocation
} from '@/utils/auth';
export default {
  data() {
    var validatePassword = (rule, value, callback) => {
      if (value.length < 6 || value.length > 30) {
        callback(new Error(this.$t('login.resetPassword.passwordRequirements')));
      } else {
        callback();
      }
    };
    var validateNewPassword = (rule, value, callback) => {
      if (value.length < 6 || value.length > 30) {
        callback(new Error(this.$t('login.resetPassword.newPasswordRequirements')));
      } else {
        callback();
      }
    };
    var validateComfirmPassword = (rule, value, callback) => {
      if (!this.dataForm.id && !/\S/.test(value)) {
        callback(new Error(this.$t('login.resetPassword.confirmPasswordNotEmpty')));
      } else if (this.dataForm.newPassword !== value) {
        callback(new Error(this.$t('login.resetPassword.newPasswordDifferent')));
      } else {
        callback();
      }
    };
    return {
      visible: false,
      dataLoading: false,
      active: 1,
      isSuccess: false,
      userInfo: getUserInfoLocation() || {},
      dataForm: {
        id: undefined,
        userName: "",
        userPwd: "",
        newPassword: "",
        comfirmPassword: "",
      },
      dataRule: {
        userPwd: [
          { required: true, message: this.$t('login.resetPassword.passwordNotEmpty'), trigger: "blur" },
          { validator: validatePassword, trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: this.$t('lgin.resetPassword.newPasswordNotEmpty'), trigger: "blur" },
          { validator: validateNewPassword, required: true, trigger: "blur" },
        ],
        comfirmPassword: [
          { required: true, message: this.$t('login.resetPassword.confirmPasswordNotEmpty'), trigger: "blur" },
          {
            validator: validateComfirmPassword,
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {

  },
  methods: {
    logBackIn() {
      this.dataForm = {}
      this.visible = false
      this.$store.dispatch("LogOut").then(() => {
        this.$router.replace('/login')
        this.$router.go(0)
      })
    },
    nextTip() {
      this.active += 1
    },
    resetForm() {
      this.active -= 1
    },
    again() {
      this.active = 1
    },
    init(row) {
      this.dataForm.id = row.id
      this.dataForm.userName = row.userName
      this.dataForm.userPwd = decrypt(row.userPwd)
      this.visible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          this.dataLoading = true;
          let params = {
            id: this.dataForm.id,
            userPwd: cryptoAes(this.dataForm.userPwd),
            newUserPwd: cryptoAes(this.dataForm.comfirmPassword),
          };
          updateInitializePwdApi(params)
            .then(() => {
              this.isSuccess = true
              this.active = 3
            })
            .finally(() => {
              this.dataLoading = false;
            });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.syMain {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .topTip {
    text-align: center;
    line-height: 30px;
    color: red;
    font-weight: 700;
    margin: 10px 0;
  }

  .tip {
    color: red;
    margin-top: 20px;

    p {
      margin: 5px
    }
  }

  .ImgTip {
    text-align: center;
    margin: 0 auto;

    p {
      margin: 20px 0;
    }
  }
}
</style>
